import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="WFH Companies - The WFH Times" />

    <h1>Companies that have closed their offices and are entirely WFH</h1>
    <ul>
      <li>Grammarly: SF, NYC, Vancouver Offices. Kyiv is WFH-encouraged (<a href="https://www.grammarly.com/blog/covid-19-safety/">march 7, 2020</a>)</li>
      <li>Facebook: One of the Seattle offices is closed. (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">march 7, 2020</a>)</li>

    </ul>
    <h1>Companies that are WFH-encouraged</h1>
    <ul>
      <li>Apple (<a href="https://www.marketwatch.com/story/facebook-apple-google-and-twitter-ask-staff-to-work-remotely-due-to-coronavirus-heres-the-bad-news-for-the-rest-of-america-2020-03-08">March 8, 2020</a>)</li>
      <li>Facebook (<a href="https://www.marketwatch.com/story/facebook-apple-google-and-twitter-ask-staff-to-work-remotely-due-to-coronavirus-heres-the-bad-news-for-the-rest-of-america-2020-03-08">March 8, 2020</a>)</li>
      <li>Twitter (<a href="https://www.marketwatch.com/story/facebook-apple-google-and-twitter-ask-staff-to-work-remotely-due-to-coronavirus-heres-the-bad-news-for-the-rest-of-america-2020-03-08">March 8, 2020</a>)</li>
      <li>Amazon (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">March 6, 2020</a>)</li>
      <li>Google (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">March 6, 2020</a>)</li>
      <li>Microsoft (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">March 6, 2020</a>)</li>
      <li>Bungie (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">March 6, 2020</a>)</li>
      <li>Nintendo (<a href="https://www.theverge.com/2020/3/5/21166686/coronavirus-amazon-google-facebook-microsoft-twitter-seattle-staff-remote-work">March 6, 2020</a>)</li>
    </ul>
    Have an update? Email <a href="mailto:companies@wfhtimes.com">companies@wfhtimes.com</a>.
  </Layout>
)

export default IndexPage
